:root {
  --drawerWidth: 230px;
  --primaryColor: #E91E63;
}

html,
body,
div,
form,
p,
ul,
li,
span,
label,
img {
  margin: 0;
  padding: 0;
  outline: none;
  box-sizing: border-box;
}

html,
body {
  height: 100%;
  width: 100%;
  background-color: #fefefe;
}

body {
  position: relative;
  font-family: 'Open Sans', Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -webkit-text-size-adjust: none;
  overflow: hidden;
}

.text-ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.text-muted {
  color: #666;
}

.blinking {
  animation: 1s blink ease infinite;
}

@keyframes blink {

  from,
  to {
    opacity: 0;
  }

  50% {
    opacity: 1;
  }
}

::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

::-webkit-scrollbar-track {
  -webkit-border-radius: 3px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
  border-radius: 3px;
  background-color: #fff;
}

::-webkit-scrollbar-thumb {
  -webkit-border-radius: 3px;
  border-radius: 3px;
  background: #dadce0;
}

::-webkit-scrollbar-thumb:window-inactive {
  background: #dadce0;
}

a {
  color: var(--primaryColor);
}